import React, { useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import Logo from 'assets/icons/logo/logo.svg';
import WhiteLogo from 'assets/icons/logo/white_logo.svg';
import BackButton from './BackButton';
import ProgressCount from './ProgressCount';
import { Color } from 'utils/theme';
import { DynamicImage } from 'components/images/DynamicImage';
import { Text } from 'components';

type LogoVariant = 'left' | 'center';

interface HeaderProps {
  countTotal?: number;
  count?: number;
  hideCount?: boolean;
  onBack?: () => void;
  logoVariant?: LogoVariant;
  RightComponent?: ReactNode;
  sticky?: boolean;
  color?: Color;
  isWhite?: boolean;
  hasBorder?: boolean;
  bannerTopText?: string;
  bannerColorsInverted?: boolean;
}

export const HEADER_HEIGHT = 72;
export const HEADER_HEIGHT_MOBILE = 56;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const Container = styled.div<{
  color?: Color;
  hasBorder?: boolean;
  hasMargin?: boolean;
}>`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme, color }) =>
    theme.colors[color ? color : 'light0']};
  border-bottom: ${({ hasBorder }) => (hasBorder ? '1px solid #436460' : '')};
  ${({ hasMargin }) => hasMargin && 'margin-top: 2.65rem;'}
  @media ${tablet} {
    width: 100%;
    overflow-x: hidden;
    height: ${HEADER_HEIGHT_MOBILE}px;
  }
`;

const LogoSvg = styled(Logo)`
  width: 150px;
  height: 32px;
  @media ${tablet} {
    z-index: 999;
    width: 110px;
    height: 24px;
  }
`;
const LogoIconStyled = styled(DynamicImage)<{ color?: Color }>`
  height: 24px;
  background-color: ${({ theme, color }) =>
    theme.colors[color ? color : 'light0']};
  @media ${tablet} {
    max-height: 20px;
    z-index: 999;
  }
`;

const ContentContainer = styled.div<{
  logoVariant: LogoVariant;
  bgColor: string;
}>`
  margin: 0 ${({ logoVariant }) => (logoVariant === 'center' ? 3 : 1)}rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor]}
  max-width: ${({ logoVariant }) =>
    logoVariant === 'left' ? '90rem' : 'none'};

  @media ${tablet} {
    margin: 0 1rem;
  }
`;

const Section = styled.div<{ justifyContent?: 'left' | 'center' | 'right' }>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
  align-items: center;
  @media ${tablet} {
    flex: unset;
  }
`;

const Gap = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;

  @media ${tablet} {
    height: ${HEADER_HEIGHT_MOBILE}px;
  }
`;

const Header: FC<HeaderProps> = ({
  logoVariant = 'center',
  countTotal,
  count,
  hideCount,
  onBack,
  RightComponent,
  sticky = true,
  color,
  isWhite,
  hasBorder,
  bannerTopText,
  bannerColorsInverted,
}) => {
  const { isTablet } = useQuery();

  const visibleBackButton = useMemo(
    () => logoVariant === 'center',
    [logoVariant],
  );

  const renderRightSectionContent = () => {
    if (logoVariant === 'left') {
      return RightComponent;
    }
    return (
      typeof countTotal === 'number' &&
      typeof count === 'number' &&
      !hideCount && <ProgressCount total={countTotal} count={count} />
    );
  };

  return (
    <>
      {sticky ? (
        <>
          <Wrap>
            {bannerTopText && (
              <ScrollingTextWrapper bannerColorsInverted={bannerColorsInverted}>
                <ScrollingTextContent
                  bannerColorsInverted={bannerColorsInverted}
                >
                  {Array(60)
                    .fill(bannerTopText)
                    .map((text, index) => (
                      <InnerBannerText key={index}>{text}</InnerBannerText>
                    ))}
                </ScrollingTextContent>
              </ScrollingTextWrapper>
            )}
            <Container color={color} hasBorder={hasBorder}>
              <ContentContainer bgColor={color} logoVariant={logoVariant}>
                {visibleBackButton && (
                  <Section justifyContent="left">
                    {onBack && (
                      <BackButton
                        onClick={onBack}
                        backText={!isTablet ? 'Back' : undefined}
                      />
                    )}
                  </Section>
                )}
                <Section justifyContent={logoVariant}>
                  {isWhite ? <WhiteLogo /> : <LogoSvg />}
                </Section>
                <Section color={color} justifyContent="right">
                  {renderRightSectionContent()}
                </Section>
              </ContentContainer>
            </Container>
          </Wrap>
          <Gap />
        </>
      ) : (
        <>
          {bannerTopText && (
            <ScrollingTextWrapper
              isSticky={true}
              bannerColorsInverted={bannerColorsInverted}
            >
              <ScrollingTextContent bannerColorsInverted={bannerColorsInverted}>
                {Array(60)
                  .fill(bannerTopText)
                  .map((text, index) => (
                    <InnerBannerText key={index}>{text}</InnerBannerText>
                  ))}
              </ScrollingTextContent>
            </ScrollingTextWrapper>
          )}
          <Container
            color={color}
            hasBorder={hasBorder}
            hasMargin={bannerTopText}
          >
            <ContentContainer bgColor={color} logoVariant={logoVariant}>
              {visibleBackButton && (
                <Section justifyContent="left">
                  {onBack && (
                    <BackButton
                      onClick={onBack}
                      backText={!isTablet ? 'Back' : undefined}
                    />
                  )}
                </Section>
              )}
              <Section color={color} justifyContent={logoVariant}>
                {isWhite ? <WhiteLogo /> : <LogoSvg />}
              </Section>
              <Section justifyContent="right">
                {renderRightSectionContent()}
              </Section>
            </ContentContainer>
          </Container>
        </>
      )}
    </>
  );
};

export default React.memo(Header);

const ScrollingTextWrapper = styled.div<{
  isSticky?: boolean;
  bannerColorsInverted?: boolean;
}>`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
  top: 0;
  height: 2.65rem;
  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 1000;
  background: ${({ bannerColorsInverted }) =>
    bannerColorsInverted ? '#fff' : '#2a4b42'};
  ${({ bannerColorsInverted }) =>
    bannerColorsInverted && 'box-shadow: 0 5px 10px rgba(67, 100, 96, 0.3);'};
`;

const ScrollingTextContent = styled.div<{ bannerColorsInverted?: boolean }>`
  display: inline-block;
  position: absolute;
  left: 0;
  will-change: transform;
  animation: scroll 100s linear infinite;
  color: ${({ bannerColorsInverted }) =>
    bannerColorsInverted ? '#2a4b42' : '#fef3f3'};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding-right: 100%;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @keyframes scroll {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const InnerBannerText = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  line-height: 1.1;
`;
